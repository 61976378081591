import React, {useState} from "react";
import Page, { DualPage } from "../../components/Page";
import {Helmet} from "react-helmet-async";
import { useBackend } from "../../utility/Backend";
import { useLoginSession } from "../../stores/loginSession";
import { useUpdateSearchParams, getDateFormat } from "../../utility/Utilities";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import UserGroupInfo from "./UserGroupInfo";
import "./Users.css";
import classNames from "classnames";
import {BiSort} from "react-icons/bi";
import {TiGroup} from "react-icons/ti";

function UserGroupDetails ({activeUserGroupId, userGroupId, updateUserGroupParams, query}) {

    const {data: group} = useBackend("/usergroup/" + userGroupId, query)
    if (!group) return null

    const isActiveGroup = activeUserGroupId === userGroupId.toString()
    
    return (
        <div 
            onClick={() => updateUserGroupParams(userGroupId)}
            className={classNames("user-group-single", {"active": isActiveGroup})}>
            <div className="user-group-single-name data">
                <div className="user-group-logo-name">
                    <div>
                        {group.log_url? (<img src={group.log_url} alt="logo"/>) : 
                        (<div><TiGroup className="table-data-group-icon"/></div>)}
                    </div>
                    <div>{group.name}</div>
                </div>
            </div>
            <div className="user-group-length">{group.users.length !== 0? group.users.length : "-"}</div>
            <div className="user-group-channel-list data">
                {group.channels.length !== 0? 
                    <div className="channel-list">
                        {group.channels.map((channel) => {
                            return <div key={channel.id}>{channel.name}</div>
                        })}
                    </div>
                : "-"}
            </div>
            <div className="user-group-created">{getDateFormat(group.created)}</div>
        </div>
    )
}

export default function Users () {

    const {token} = useLoginSession()
    const query = {access_token: token}
    const [searchParams, updateSearchParams,] = useUpdateSearchParams();
    const [,isAdmin] = useCheckIsAdmin();

    const [searchUserGroup, setSearchUserGroup] = useState("")
    const [ascending, setAscending] = useState(true)

    const {data} = useBackend("/usergroup", query)
    if (!data) return null

    if (!isAdmin) return (
        <Page>
            <div className="only-available">
                You don’t have permission to access this page.
            </div>
        </Page>
    )

    let userGroups = data.usergroups

    const ascendingSort = (a, b) => a.name.localeCompare(b.name)
    const descendingSort = (a, b) => b.name.localeCompare(a.name)
    userGroups.sort(ascendingSort)
    if (!ascending) userGroups.sort(descendingSort)

    if (searchUserGroup !== "") {
        userGroups = userGroups.filter((group) => group.name.toLowerCase().includes(searchUserGroup.toLowerCase()));
    }

    const activeUserGroupId = searchParams.get("user_group_info")

    const updateUserGroupParams = (id) => {
        updateSearchParams({"user_group_info": id})
    }

    const userGroupsList = (          
        <div className="user-groups-table scrollable">
            <div className="table-header-cont sticky">
                <div onClick={() => setAscending(!ascending)} className="user-group-single-name table-header-sort">
                    User group
                    <BiSort className="table-header-sort-icon"/>
                </div>
                <div className="user-group-length">Number of users</div>
                <div className="user-group-channel-list">Group channels</div>
                <div className="user-group-created">Group created</div>
            </div>
            {userGroups.map((group) => {
                return (
                    <UserGroupDetails
                        key={group.id}
                        activeUserGroupId={activeUserGroupId} 
                        userGroupId={group.id}
                        updateUserGroupParams={updateUserGroupParams}
                        query={query}/>
                )
            })}
        </div>          
    )

    const emptyUserGroupPage = (
        <div className="flex-vertically-centered is-loading">
            <div>
                <TiGroup className="no-group-selected-icon"/>
                <br />
                None selected
            </div>
        </div>
    )

    return (
        <DualPage withLayoutSelection defaultLayout={0} rightPageQueryParam="user_group_info">
            <Page title="user groups">
                <Helmet>
                    <title>User Groups</title>
                </Helmet>
                <div className="user-groups-cont">
                    <div className="user-groups-search">
                        Search
                        <input 
                            type="text" 
                            value={searchUserGroup}
                            onChange={(e) => setSearchUserGroup(e.target.value)}/>
                    </div>
                    {userGroupsList}
                </div>
            </Page>
            <Page>
                {activeUserGroupId && <UserGroupInfo activeUserGroupId={activeUserGroupId}/>}
                {!activeUserGroupId && emptyUserGroupPage}
            </Page>
        </DualPage>
    );
}