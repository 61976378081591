import React, {useEffect, useState, useRef} from "react"
import Config from "../../utility/Config";
import Page from "../../components/Page"
import classNames from "classnames";
import { capitalizeFirstLetter } from "../../utility/Utilities";
import Dropdown from "../../components/Dropdown";
import { useGetChannels } from "../../utility/UseGetChannels";
import { useUpdateSearchParams } from "../../utility/Utilities";
import { IoMdArrowDropdown } from "react-icons/io"
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { ClickOutside } from "../../utility/Utilities";
import { graphSiteDictionary } from "../../utility/StatisticGraphs";
import { usePeriodFilter } from "../../utility/UsePeriodFilter";
import "../../DataInfoUtility.css"
import "./Statistics.css"
import "../../components/css/ListingFilter.css"

function Statistics () {

    const statisticGraphs = Config.statisticGraphs
    const {from, to, periodFilterOptions} = usePeriodFilter()
    const [searchParams, updateSearchParams, resetAllSearchParamsExcept] = useUpdateSearchParams()
    const [,isAdmin] = useCheckIsAdmin()
    const leagueWithStatistics = ["SEF", "NTF", "toppserien", "SFL"]
    const showStatisticPage = leagueWithStatistics.includes(Config.association)

    const [openStatisticTypeFilter, setOpenStatisticTypeFilter] = useState(false)
    const [openSiteFilter, setOpenSiteFilter] = useState(false)
    const [openContentFilter, setOpenContentFilter] = useState(false)
    const [openVideoTypeFilter, setOpenVideoTypeFilter] = useState(false)
    const [hideGraphs, setHideGraphs] = useState(true)

    const channelsList = useGetChannels() || []
    const channels = channelsList.reduce((agg, c) => {agg[c.id] = c; return agg;}, {})
    const channelsById = Object.keys(channels)
    const channelOptions = channelsById.sort((a,b) => channels[a].name.localeCompare(channels[b].name))
    const defaultChannel = (!isAdmin && channelOptions.length !== 0) ? channelOptions[0] : undefined

    const statisticTypeParam = searchParams.get("type")
    const siteParam = searchParams.get("site")
    const videoContentParam = searchParams.get("content")
    const videoTypeParam = searchParams.get("video_type")
    const statisticChannelParam = searchParams.get("channel") || defaultChannel

    useEffect(() => {
        if (!showStatisticPage) return
        if (!statisticTypeParam) updateSearchParams({"type": "view_count"})
    }, [])

    useEffect(() => {
        if (statisticTypeParam === "other") resetAllSearchParamsExcept(["type", "from", "to"])
    }, [statisticTypeParam])

    useEffect(() => {
        setHideGraphs(true)
    }, [from, to])

    useEffect(() => {
        const clearRefreshStream = setInterval(()=> {
            if (hideGraphs) setHideGraphs(false)
        }, 800)
        return () => clearInterval(clearRefreshStream);
    }, [hideGraphs])

    const statisticTypeDropdownRef = useRef(null)
    const siteDropdownRef = useRef(null)
    const contentDropdownRef = useRef(null)
    const videoTypeDropdownRef = useRef(null)

    ClickOutside(statisticTypeDropdownRef, setOpenStatisticTypeFilter)
    ClickOutside(siteDropdownRef, setOpenSiteFilter)
    ClickOutside(contentDropdownRef, setOpenContentFilter)
    ClickOutside(videoTypeDropdownRef, setOpenVideoTypeFilter)

    if (!statisticGraphs) return null
    if (!from && !to) return null
    if (!showStatisticPage) return null

    const onChangeStatisticType = (type) => {
        if (statisticTypeParam === type) return
        setHideGraphs(true)
        updateSearchParams({"type": type})
    }

    const onChangeSite = (site) => {
        if (siteParam === site) return
        if (!site) updateSearchParams("site")
        else updateSearchParams({"site": site})
        setHideGraphs(true)
    }

    const onChangeContent = (content) => {
        if (videoContentParam === content) return
        if (!content) updateSearchParams("content")
        else updateSearchParams({"content": content})
        setHideGraphs(true)
    }

    const onChangeVideoType = (type) => {
        if (videoTypeParam === type) return
        if (!type) updateSearchParams("video_type")
        else updateSearchParams({"video_type": type})
        setHideGraphs(true)
    }

    const onChangeStatisticChannel = (channel) => {
        if (statisticChannelParam === channel) return
        setHideGraphs(true)
        updateSearchParams({"channel": channel})
    }

    const statisticTypeOptions = isAdmin ? ["view_count", "duration", "other"] : ["view_count", "duration"]
    const siteOptions = statisticGraphs.site || null
    const videoContentOptions = ["official_events", "club_content"]
    const videoTypeOptions = ["live", "VoD"]

    // TODO maybe make this to a usable component, so save some space
    const statisticTypeFilter = (
        <div
            ref={statisticTypeDropdownRef}
            onClick={() => setOpenStatisticTypeFilter(!openStatisticTypeFilter)}  
            className={classNames("filter-dropdown", {"open": openStatisticTypeFilter})}
            >
            <div className="filter-title">Statistic type</div>
            <div className="filter-selected">
                {statisticTypeParam && capitalizeFirstLetter(statisticTypeParam).replace("_", " ")}
                <span className="dropdown-arrow"><IoMdArrowDropdown /></span>
            </div>
            <ul className="filter-dropdown-list">
                {statisticTypeOptions.map((t) => {
                    return (
                        <li 
                            key={t}
                            onClick={() => onChangeStatisticType(t)} 
                            className={classNames("dropdown-item", {"active": statisticTypeParam === t})}>
                            {capitalizeFirstLetter(t).replace("_", " ")}
                        </li>
                    )
                })}
            </ul>
        </div>
    )

    const siteFilter = siteOptions && (
        <div
            ref={siteDropdownRef}
            onClick={() => setOpenSiteFilter(!openSiteFilter)}  
            className={classNames("filter-dropdown", {"open": openSiteFilter})}
            >
            <div className="filter-title">Site</div>
            <div className="filter-selected">
                {siteParam ? capitalizeFirstLetter(siteParam): "All"}
                <span className="dropdown-arrow"><IoMdArrowDropdown /></span>
            </div>
            <ul className="filter-dropdown-list">
                <li 
                    onClick={() => onChangeSite()} 
                    className={classNames("dropdown-item", {"active": !siteParam})}>
                    All
                </li>
                {siteOptions.map((s) => {
                    return (
                        <li 
                            key={s}
                            onClick={() => onChangeSite(s)} 
                            className={classNames("dropdown-item", {"active": siteParam === s})}>
                            {capitalizeFirstLetter(s)}
                        </li>
                    )
                })}
            </ul>
        </div>
    )

    const videoContentFilter = (
        <div
            ref={contentDropdownRef}
            onClick={() => setOpenContentFilter(!openContentFilter)}  
            className={classNames("filter-dropdown", {"open": openContentFilter})}
            >
            <div className="filter-title">Content</div>
            <div className="filter-selected">
                {videoContentParam ? capitalizeFirstLetter(videoContentParam).replace("_", " ") : "All"}
                <span className="dropdown-arrow"><IoMdArrowDropdown /></span>
            </div>
            <ul className="filter-dropdown-list">
                <li 
                    onClick={() => onChangeContent()} 
                    className={classNames("dropdown-item", {"active": !videoContentParam})}>
                    All
                </li>
                {videoContentOptions.map((c) => {
                    return (
                        <li 
                            key={c}
                            onClick={() => onChangeContent(c)} 
                            className={classNames("dropdown-item", {"active": videoContentParam === c})}>
                            {capitalizeFirstLetter(c).replace("_", " ")}
                        </li>
                    )
                })}
            </ul>
        </div>
    )

    const videoTypeFilter = (
        <div
            ref={videoTypeDropdownRef}
            onClick={() => setOpenVideoTypeFilter(!openVideoTypeFilter)}  
            className={classNames("filter-dropdown", {"open": openVideoTypeFilter})}
            >
            <div className="filter-title">Video type</div>
            <div className="filter-selected">
                {videoTypeParam ? capitalizeFirstLetter(videoTypeParam) : "All"}
                <span className="dropdown-arrow"><IoMdArrowDropdown /></span>
            </div>
            <ul className="filter-dropdown-list">
                <li 
                    onClick={() => onChangeVideoType()} 
                    className={classNames("dropdown-item", {"active": !videoTypeParam})}>
                    All
                </li>
                {videoTypeOptions.map((s) => {
                    return (
                        <li 
                            key={s}
                            onClick={() => onChangeVideoType(s)} 
                            className={classNames("dropdown-item", {"active": videoTypeParam === s})}>
                            {capitalizeFirstLetter(s)}
                        </li>
                    )
                })}
            </ul>
        </div>
    )

    const channelFilter = (
        <Dropdown
            title="Channel"
            centered
            special={isAdmin && "All"}
            fallback={isAdmin && "All"}
            selected={statisticChannelParam}
            options={channelOptions}
            render={(id) => channels[id]?.name || ""}
            btnRender={(id) => channels[id]?.name || ""}
            onChange={v => onChangeStatisticChannel(v)}/>
    )

    const statisticFilters = (
        <div className="statistic-filters-cont">
            {periodFilterOptions}
            {statisticTypeFilter}
            {statisticTypeParam !== "other" && (
                <>
                    {siteFilter}
                    {videoContentFilter}
                    {videoTypeFilter}
                    {channelFilter}
                </>
            )}
        </div>
    )

    const getStatisticUrl = (type, channel=false) => {

        const url = statisticGraphs[type].url
        const orgId = `?orgId=${statisticGraphs[type].orgId}`
        const panelId = `&panelId=${statisticGraphs[type].panelId}`
        const fromDate = `&from=${from}`
        const toDate = `&to=${to}`

        const siteNumber = graphSiteDictionary[siteParam] || null
        const site = `&var-http_origin=${siteNumber || "All"}`

        let statisticUrl = url + orgId + panelId + fromDate + toDate + site

        if (channel && statisticChannelParam) {
            statisticUrl += `&var-channel_id=${statisticChannelParam}`
        }
        
        if (videoContentParam) {
            if (videoContentParam === "official_events") statisticUrl += "&var-is_event=t"
            if (videoContentParam === "club_content") statisticUrl += "&var-is_event=f"
        }

        if (videoTypeParam) {
            if (videoTypeParam === "live") statisticUrl += "&var-is_live=t"
            if (videoTypeParam === "VoD") statisticUrl += "&var-is_live=f"
        }
        
        return statisticUrl
    }
    
    const viewCountNumberUrl = getStatisticUrl("viewCountNumber")
    const viewCountTableUrl = getStatisticUrl("viewCountTable")
    const viewCountGraphUrl = getStatisticUrl("viewCountGraph")

    const durationNumberUrl = getStatisticUrl("durationNumber")
    const durationTableUrl = getStatisticUrl("durationTable")
    const durationGraphUrl = getStatisticUrl("durationGraph")
    
    const channelViewNumberUrl = getStatisticUrl("channelViewNumber", true)
    const channelViewGraphUrl = getStatisticUrl("channelViewGraph", true)
    const channelViewTableUrl = getStatisticUrl("channelViewTable", true)

    const channelDurationNumberUrl = getStatisticUrl("channelDurationNumber", true)
    const channelDurationGraphUrl = getStatisticUrl("channelDurationGraph", true)
    const channelDurationTableUrl = getStatisticUrl("channelDurationTable", true)

    const deviceUsageGraphUrl = getStatisticUrl("deviceUsageGraph")

    const globalStatistic = isAdmin && !statisticChannelParam

    const globalViewCountStatistics = (
        <div className="statistic-single-cont">
            <div className="statistic-flex smaller-left">
                <div className="statistic-number">
                    <iframe src={viewCountNumberUrl} title="duration table" frameborder="0"></iframe>
                </div>
                <div className="statistic-table">
                    <iframe src={viewCountTableUrl} title="view count table" frameborder="0"></iframe>
                </div>
            </div>
            <div className="statistic-graph">
                <iframe src={viewCountGraphUrl}  title="view count" frameborder="0"></iframe>
            </div>
        </div>
    )

    const globalDurationStatistics = (
        <div className="statistic-single-cont">
            <div className="statistic-flex">
                <div className="statistic-number">
                    <iframe src={durationNumberUrl} title="duration table" frameborder="0"></iframe>
                </div>
                {globalStatistic && (
                    <div className="statistic-table">
                        <iframe src={durationTableUrl} title="duration table" frameborder="0"></iframe>
                    </div>
                )}
            </div>
            <div className="statistic-graph">
                <iframe src={durationGraphUrl} title="duration" frameborder="0"></iframe>
            </div>
        </div>
    )

    const channelViewCountStatistic = (
        <div className="statistic-single-cont">
            <div className="statistic-flex">
                <div className="statistic-number">
                    <iframe src={channelViewNumberUrl} title="duration table" frameborder="0"></iframe>
                </div>
                <div className="statistic-table">
                    <iframe src={channelViewTableUrl} title="view count table" frameborder="0"></iframe>
                </div>
            </div>
            <div className="statistic-graph">
                <iframe src={channelViewGraphUrl}  title="view count" frameborder="0"></iframe>
            </div>
        </div>
    )

    const channelDurationStatistic = (
        <div className="statistic-single-cont">
            <div className="statistic-flex">
                <div className="statistic-number">
                    <iframe src={channelDurationNumberUrl} title="duration table" frameborder="0"></iframe>
                </div>
                <div className="statistic-table">
                    <iframe src={channelDurationTableUrl} title="view count table" frameborder="0"></iframe>
                </div>
            </div>
            <div className="statistic-graph">
                <iframe src={channelDurationGraphUrl} title="duration" frameborder="0"></iframe>
            </div>
        </div>
    )

    const deviceUsageGraph = (
        <div className="statistic-graph-small">
            <iframe src={deviceUsageGraphUrl} title="duration" frameborder="0"></iframe>
        </div>
    )

    const viewCountStatistics = globalStatistic ? globalViewCountStatistics : channelViewCountStatistic
    const durationStatistics = globalStatistic ? globalDurationStatistics : channelDurationStatistic

    return (
        <Page title="Statistics">
            <div className="statistic-cont">
                {statisticFilters}
                {statisticTypeParam === "view_count" && viewCountStatistics}
                {statisticTypeParam === "duration" && durationStatistics}
                {statisticTypeParam === "other" && deviceUsageGraph}
                {hideGraphs && <div className="blank-page-bg"></div>}
            </div>
        </Page>
    )
}

export default Statistics