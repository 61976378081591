import { useLoginSession } from "../stores/loginSession"
import { useBackend } from "./Backend"

export function useGetUsersGroups () {

    const {user, token} = useLoginSession()
    const query = {access_token: token}

    const {data} = useBackend(user ? `/user/${user?.id}/usergroups` : null, query)
    if (!data) return []
    
    return data.usergroups
}

export function useCheckIsAdmin () {
    
    const usersUserGroups = useGetUsersGroups();

    if (!usersUserGroups) return false

    const usersUserGroupsName = usersUserGroups.map(group => group.name)
    const admin = ["fs_admin", "sef_admin", "shl_admin", "toppserien_admin", "eu_admin"]

    const isFsAdmin = usersUserGroupsName.some(group => group.includes(admin[0]))
    const isAdmin = usersUserGroupsName.some(group => admin.includes(group))
    
    return [isFsAdmin, isAdmin]
}

export function useCheckPermissions (playlist) {

    const {user} = useLoginSession()
    const usersUserGroups = useGetUsersGroups()
    const [,isAdmin] = useCheckIsAdmin()
    
    const usersUserGroupsId = usersUserGroups.map(group => group.id)

    const playlistCreator = playlist.creator.id === user.id
    const inEditPermittedList = playlist.usergroups_edit?.map(group => group.id).some(id => usersUserGroupsId.includes(id))
    const inGrantPermittedList = playlist.usergroups_grant?.map(group => group.id).some(id => usersUserGroupsId.includes(id))

    const hasEditPermission = isAdmin || playlistCreator || inEditPermittedList
    const hasGrantPermission = isAdmin || inGrantPermittedList
    const hasSubclipPermission = isAdmin || playlistCreator || true  // FIXME: !!! Quick hack for SHL

    return [hasEditPermission, hasGrantPermission, hasSubclipPermission]
}